export const DEFAULT_CAROUSEL_CONFIG = {
  slidesPerView: 5,
  spaceBetween: 20,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  breakpoints: {
    1580: {
      slidesPerView: 7,
    },
    1440: {
      slidesPerView: 5,
    },
    1024: {
      slidesPerView: 4,
    },
    1020: {
      spaceBetween: 16,
    },
    768: {
      slidesPerView: 3,
    },
    480: {
      slidesPerView: 2,
    },
    300: {
      slidesPerView: 1,
      spaceBetween: 12,
    },
  },
  preloadImages: false,
  watchSlidesProgress: true,
  lazy: true,
};

export const CIRCLE_CAROUSEL_ITEMS_CONFIG = {
  ...DEFAULT_CAROUSEL_CONFIG,
  slidesPerView: 9,
  spaceBetween: 18,
  breakpoints: {
    1580: {
      slidesPerView: 11,
      spaceBetween: 32,
    },
    1440: {
      slidesPerView: 9,
      spaceBetween: 32,
    },
    1240: {
      slidesPerView: 8,
      spaceBetween: 32,
    },
    1024: {
      slidesPerView: 7,
      spaceBetween: 32,
    },
    1020: {
      spaceBetween: 18,
    },
    890: {
      slidesPerView: 6,
    },
    770: {
      slidesPerView: 5,
    },
    520: {
      slidesPerView: 3,
    },
    300: {
      slidesPerView: 2,
      spaceBetween: 0,
    },
  },
};

export const VERTICAL_CAROUSEL_ITEMS_CONFIG = {
  ...DEFAULT_CAROUSEL_CONFIG,
  slidesPerView: 8,
  spaceBetween: 12,
  breakpoints: {
    1650: {
      slidesPerView: 10,
      spaceBetween: 32,
    },
    1580: {
      slidesPerView: 9,
      spaceBetween: 32,
    },
    1440: {
      slidesPerView: 7,
      spaceBetween: 32,
    },
    1024: {
      slidesPerView: 6,
      spaceBetween: 32,
    },
    768: {
      slidesPerView: 5,
      spaceBetween: 12,
    },
    520: {
      slidesPerView: 3,
    },
    300: {
      slidesPerView: 2,
      spaceBetween: 10,
    },
  },
};

export const CATALOG_CAROUSEL_ITEMS_CONFIG = {
  ...DEFAULT_CAROUSEL_CONFIG,
  slidesPerView: 9,
  spaceBetween: 16,
  breakpoints: {
    1580: {
      slidesPerView: 9,
      spaceBetween: 10,
    },
    1440: {
      slidesPerView: 8,
      spaceBetween: 9,
    },
    1240: {
      slidesPerView: 6,
      spaceBetween: 9,
    },
    1024: {
      slidesPerView: 5,
      spaceBetween: 9,
    },
    890: {
      slidesPerView: 5,
      spaceBetween: 9,
    },
    770: {
      slidesPerView: 4,
      spaceBetween: 9,
    },
    520: {
      slidesPerView: 3,
      spaceBetween: 9,
    },
    300: {
      slidesPerView: 2,
      spaceBetween: 9,
    },
  },
};
