<template>
  <router-link
    v-if="previewItems.length"
    class="item"
    :class="itemsType"
    :to="itemsType === 'circle' ? link : linkAll"
  >
    <circle-item-type v-if="itemsType === 'circle'" :items="previewItems"/>
    <vertical-item-type v-if="itemsType === 'vertical'" :items="previewItems"/>
    <horizontal-item-type v-if="itemsType === 'horizontal'" :items="previewItems"/>
    <div class="link">
      {{ $t('CATEGORIES.MORE') }}
      <i class="fal fa-long-arrow-right"/>
    </div>
  </router-link>
</template>
<script>
  import CircleItemType from './circle-item-type';
  import VerticalItemType from './vertical-item-type';
  import HorizontalItemType from './horizontal-item-type';

  export default {
    name: 'MoreItem',
    components: {
      CircleItemType,
      VerticalItemType,
      HorizontalItemType,
    },
    props: {
      area: {
        type: Object,
        default: () => {},
      },
      items: {
        type: Array,
        required: true,
        default: () => [],
      },
      itemsType: {
        type: String,
        default: () => 'horizontal',
      },
      link: {
        type: String,
        default: '',
      },
    },
    data: function () {
      return {}
    },
    computed: {
      previewItems() {
        switch (this.itemsType) {
          case 'vertical':
            return this.getItems(12);
          case 'circle':
            return this.getItems(7);
          case 'horizontal':
            return this.getItems(9);
          default:
            return [];
        }
      },
      linkAll() {
        if (this.area.id === 'all-events') {
          return `/get_section/${this.area.id}/?linkOnAir=${encodeURIComponent(this.area.linkOnAir)}&linkComingSoon=${encodeURIComponent(this.area.linkComingSoon)}&title=${this.area.title.includes('&') ? this.area.title.replaceAll('&', '^*^') : this.area?.title}`
        }
        return this.area ? `/get_section/${this.area?.id}/?link=${this.area?.link}&title=${this.area?.title}` : this.link;
      },
    },
    methods: {
      getItems(count) {
        if (this.items.length < count) { return [] }
        return this
          .items
          .slice(0, count)
          .map(item => {
            const image = item?.backdrop || item?.thumbnail || item?.poster  || item?.creator?.picture;

            return { id: item.id, image };
          });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .item {
    &.circle {
      display: block;
      padding: 16px 20px 22px;
      width: 213px;
    }
    &:hover {
      .link {
        color: $main-color;
      }
    }
  }
  .link {
    display: inline-block;
    margin-top: 12px;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: $main-text-color;

    i {
      vertical-align: bottom;
      margin-left: 6px;
    }
  }
</style>
