<template src="./swiper-carousel.html" />
<script>
  import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
  import CarouselItem from './carousel-item/carousel-item';
  import MoreItem from './more-item/more-item';
  import device from '@/service/device-service';
  import { DEFAULT_CAROUSEL_CONFIG } from '@/config/carousel.config';

  export default {
    name: 'SwiperCarousel',
    components: {
      Swiper,
      SwiperSlide,
      CarouselItem,
      MoreItem,
    },
    directives: {
      swiper: directive,
    },
    props: {
      area: {
        type: Object,
        default: () => {},
      },
      items: {
        type: Array,
        required: true,
        default: () => [],
      },
      itemsType: {
        type: String,
        default: () => 'horizontal',
      },
      itemVariant: {
        type: String,
        default: '',
      },
      config: {
        type: Object,
        default: () => DEFAULT_CAROUSEL_CONFIG,
      },
      hideMoreBtn: {
        type: Boolean,
        default: false,
      },
      hideArrows: {
        type: Boolean,
        default: false,
      },
      link: {
        type: String,
        default: '',
      },
      showOnSearchPage: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        isHovered: false,
        isVisible: false,
      };
    },
    computed: {
      isMobile() {
        return device.isAnyMobile();
      },
    },
    methods: {
      handleClickCarouselItem(item) {
        this.$emit('click:item', item);
      },
      changeSlide() {
        const swiper = this.$refs.swiper.$swiper;

        if (+swiper.currentBreakpoint > 300) {
          return;
        }

        this.$refs.carouselItem[swiper.activeIndex].runPreview();
      },
      observeVisibility(isVisible, entry, firstItem) {
        if (firstItem?.itype !== 'item_mov_vod_shoprz') {
          return;
        }

        const swiper = this.$refs.swiper.$swiper;

        this.isVisible = isVisible;

        if (isVisible && +swiper.currentBreakpoint <= 300) {
          this.$refs.carouselItem[swiper.activeIndex].runPreview();
        }

        if (!isVisible) {
          this.$refs.carouselItem[swiper.activeIndex].stopPreview();
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .my-scrollbar {
    position: absolute;
    z-index: 50;
    height: 6px;
    width: 100%;
    bottom: 0;
    left: 0;
    overflow: hidden;
  }

  .my-scrollbar-thumb {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #000;
    transform-origin: left center;
    transition: transform 0.2s ease-in-out;
  }
  .carousel {
    display: block;
    margin-bottom: 30px;
    min-height: 217px;
    position: relative;

    &::v-deep .swiper-container {
      overflow: unset;
    }

    .more-btn {
      // padding: 16px 20px 22px;
      display: block;
    }

    .swiper-action {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 52px;
      height: 52px;
      border-radius: 50%;
      border: 3px solid #fff;
      background-color: $container-background-color;

      @media (max-width: 768px) {
        display: none !important;
      }

      &:hover {
        background-color: $main-color;

        &:after {
          color: $white;
        }
      }

      &:after {
        font-size: 14px;
        font-weight: 900;
      }

      &.swiper-button-prev {
        left: -26px;
      }

      &.swiper-button-next {
        right: -26px;
      }

      &.swiper-button-disabled {
        visibility: hidden !important;
        opacity: 0 !important;
      }
    }
  }

  @media (min-width: 1024px) {
    .carousel {
      margin-bottom: 52px;
    }
  }
</style>
